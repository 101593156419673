<template>
  <div>
    <v-row>
      <v-col class="col-md-9 pr-md-1 sm-6">
        <h1 class="font-weight-light mb-2">Requests</h1>
        <span class="overline">Below are your recent requests</span>
      </v-col>
      <v-col class="col-md-3 pr-md-1 fill-width">
        <v-btn @click="createNewRequest" class="primary">Create Request</v-btn>
      </v-col>
    </v-row>

    <v-card class="py-2 pa-0">
      <v-data-table
        :headers="headers"
        :items="getCompanyRequests"
        item-key="id"
        dark
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
      >
        <template v-slot:item="{ item }">
          <tr @click="loadRequestDetails(item.id)">
            <td>
              {{
                item.pickupLocationLabel !== undefined
                  ? item.pickupLocationLabel
                  : getFreelancerCompany.locationLabel
              }}
            </td>
            <td>
              {{
                item.destinationLocationLabel !== undefined
                  ? item.destinationLocationLabel
                  : item.destination
              }}
            </td>
            <td>{{ new Date(item.timeRequestedTimeMillis) | exactDay }}</td>
            <td>{{ item.status }}</td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import Vue from "vue";
import dayjs from "dayjs";
import { mapGetters, mapMutations, mapActions, mapState } from "vuex";
export default {
  template: "#systemUsers",
  data: () => ({
    valid: false,
    rules: {
      required: (value) => !!value || "This field is required",
      email: (v) => /.+@.+\..+/.test(v) || "Must be a valid email",
    },
    headers: [
      {
        text: "Departure",
        align: "left",
        sortable: false,
        value: "departure",
      },
      { text: "Destination", value: "destination" },
      // { text: "Email", value: "email" },
      { text: "Time Requested", value: "timeRequestedTimeMillis" },
      { text: "Status", value: "status" },
    ],
    sortBy: "timeRequestedTimeMillis",
    sortDesc: true,
  }),

  beforeCreate() {
    this.$store.dispatch("fetchMyCompany");
    if (this.$route.query.requestType === "pending") {
      this.$store.dispatch(
        "fetchMyCompanyRequests",
        this.$route.query.requestType
      );
    } else {
      this.$store.dispatch("fetchMyCompanyRequests", "all");
    }
  },

  computed: {
    ...mapState(["companyRequests", "freelancerCompany"]),
    ...mapGetters([
      "getCompanyRequests",
      "messageAlert",
      "getFreelancerCompany",
    ]),
  },

  watch: {
    messageAlert(val) {
      if (val != null) {
        Vue.notify({
          group: "foo",
          title: val.title,
          text: val.body,
        });
        this.dialogCreateRequest = false;
        this.$store.dispatch("fetchMyCompanyRequests");
      }
    },
  },

  methods: {
    loadRequestDetails(requestID) {
      this.$router.push({
        path: "/requestDetails",
        query: { requestID: requestID },
      });
    },

    createNewRequest() {
      this.$router.push({
        path: "/createNewRequest",
        query: {},
      });
    },
  },

  filters: {
    exactDay(date) {
      return dayjs(date).format("h:mm a, MMMM DD YYYY");
    },
  },
};
</script>
